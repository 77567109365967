import React, { Component, useEffect, useState } from 'react';
import styles from './ProductsImageCarousel.module.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import * as imagesService from './services/image.service';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import { Image } from './models';
import { decodeImageService } from '../../../../shared/services';
import { Carousel } from 'react-bootstrap';
import './arrows-styles.scss';


function getImages() {
    return imagesService.getSliderImages();
}

export default function ProductsImageCarousel() {
    const [images, setImages] = useState<Image[]>();

    useEffect(() => {
        getImages().then((response: any) => {
            const filteredImages = response?.data.filter((image: any) => image.isActive === true);
            setImages(filteredImages);
        });
    }, []);

    return (
        <div className={styles.product_carousel_container}>
            <Carousel className={styles.carousel_wrapper} interval={5000} controls={true}>
                {images && images?.map((image: Image, index) => (
                    <Carousel.Item key={index} className={styles.image_item} >
                        <a className={styles.link_wrapper} href={image.url}>
                            <img src={decodeImageService(image.content, image.name)} alt={image.name} />
                        </a>
                    </Carousel.Item>
                ))}
            </Carousel>
        </div>
    )
}


